/* stylelint-disable */
@import "../../themes/generated/variables.base.scss";

.news-card {
  
  .dx-card{
    flex-grow: 0;
    width: 100%;
    margin: auto auto;
    padding: 5px;

    .screen-x-small & {
      flex-grow: 1;
      width: 100%;
      height: 100%;
      margin: 0;
      border: 0;
      border-radius: 0;
      box-shadow: none;
    }

    .header {
      margin-bottom: 10px;

      .title {
        color: $base-text-color;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
      }

      .description {
        color: rgba($base-text-color, alpha($base-text-color) * 0.7);
        line-height: 18px;
      }
    }
  }
}
