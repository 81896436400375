@import '../../../scss/variables.scss';

$base-green: #32a050;
$base-red: #8c2828;
$base-gray: #333;
$white: #fff;
$white-50: #ddd;
$bgMain: rgb(148 148 148);
$font-size: 30px;
$font-search: 14px;
$font-size-18: 18px;
$weight-bold: 700;

.ep_data_grid {
  height: calc(100vh - 140px);
}

.padding_0_10 {
  padding: 0 10px;
}
// ================================
.row_2x {
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-right: 4%;
    margin-bottom: 28px;
    text-align: right;
  }
}

.flex_wrap {
  flex-wrap: wrap;
}

.custome_search_wrap {
  position: relative;
  margin-right: 70px;
  margin-bottom: 24px;
  padding-top: 24px;
}

.custome_search_list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  height: auto;
  max-height: 144px;
  overflow-y: auto;
  background-color: $white;
  border-top-width: 0;
  border-bottom-width: 1px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}

.custome_search_button {
  position: absolute;
  top: 24px;
  left: 100%;
}

.custome_search_text {
  position: absolute;
  top: 2px;
  right: -60px;
  left: 0;
  color: $base-red;
  font-size: $font-search;
}

.red {
  color: $base-red;
}

.res_title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 50px;
  margin: 0 auto;
  color: $base-green;

  span {
    margin-right: 50px;
  }
}

.res_loader {
  position: absolute;
  top: 0;
  right: 0;
}

.mr_40 {
  margin-right: 40px;
}

.mr_60 {
  margin-right: 60px;
}

.policy_card_wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;

  & > * {
    width: fit-content;
    max-width: 50%;
    padding-right: 5%;
  }

  @media screen and (max-width: 767px) {
    padding: 25px;

    & > * {
      width: 100%;
      max-width: 100%;
    }
  }
}

.text_item_wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  margin-bottom: 25px;
  font-size: $font-size-normal;
  line-height: 1.5;

  span {
    margin-right: 5px;
  }
}

.padding_15 {
  padding: 15px;
}

.container {
  position: relative;
  z-index: 10;
  min-height: calc(100vh - 114px);
  padding: 15px;
  font-size: $font-size-normal;
}

.iframe_container {
  padding: 0 40px;

  iframe {
    width: 100%;
    min-height: 700px;
  }
}

.sum {
  color: $base-green;
  font-weight: $weight-bold;
  font-size: $font-size-18;
}

.formElementDiv {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
}

.buttonsdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.image_button {
  padding: 5px;
}
