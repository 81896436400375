.field_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  .field_title {
    flex-grow: 1;
    padding-top: 8px;
    padding-right: 10px;
  }

  @media screen and (max-width: 940px) {
    .axis_wrapper {
      max-width: 260px;
    }
  }
}

.four_cols {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input_label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 600px;
}

.axis_wrapper {
  display: flex;
  flex-wrap: wrap;
}

.axis_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px 10px;
}

.radioGroup_wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px;
}

.border_red {
  border: 1px solid rgb(244, 67, 54);
  border-radius: 2px;
  box-shadow: 0 0 2px 1px rgba(244, 67, 54, 0.7) !important;
}

.p_0_10 {
  padding: 0 10px;
}

.pad_r {
  padding-right: 10px;
}

.w_100 {
  width: 100%;
}

.border_orange {
  border: 1px solid rgb(230, 150, 0) !important;
  border-radius: 2px;
  box-shadow: 0 0 2px 1px rgba(230, 150, 0, 0.7) !important;
}

.text_area_wrap {
  textarea {
    padding: 7px !important;
  }
}
