.fieldRow {
    width: 100%;
    position: relative;
    color: rgba(0, 0, 0, .87);
    font-weight: 400;
    font-size: 13px;
    line-height: 1.2857;
    padding: 0;
    display: flex;
    align-items: center;
  }
  
  .fieldLabel {
    float: left;
    width: 100px;
    // overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  
    font-size: 12px;
    cursor: default;
    align-self: center;
    padding-right: 12px;
  } 
  
  .fieldValue {
    display:flex;
    flex-grow: 1;
    width: 100%;
  } 
  
  .fieldError{
    padding-left: 80px;
    color: red;
    font-size: smaller;
  }