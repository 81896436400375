@import 'scss/variables.scss';

.policieGroup {
  display: flex;
  background-color: $color-hilite;
}

.policieElement {
  display: flex;
  align-items: center;
}

.policieLabel {
  padding-right: 10px;
  padding-left: 10px;
}
