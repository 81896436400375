$balck: #000;
$grey: rgba(185, 185, 185, 0.4);
$red: red;

.form_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  column-gap: 5px;
  row-gap: 10px;
}

.form_header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 10px 3px;
}

.form_title {
  text-align: center;
}

.form_line {
  width: 90%;
  height: 2px;
  margin: 0 auto 20px;
  background-color: $balck;
}

.fifty_percent_line {
  width: 50%;
  margin-top: 30px;
}

.form_button {
  box-sizing: border-box;
  width: 400px;
  height: 40px;
  margin-top: 15px;
  padding: 10px;
}

.button {
  box-sizing: border-box;
  width: fit-content;
  min-width: 80px;
  height: 40px;
  margin-top: 15px;
  padding: 10px;
}

.line_wrapper {
  width: 100%;
}

.row_wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  text-align: right;
  column-gap: 75px;
  row-gap: 15px;
}

.input_group {
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin-right: 10px;
    margin-bottom: 5px;
  }
}

.input_rb_groups {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.border_orange {
  border: 1px solid rgb(230, 150, 0) !important;
  border-radius: 2px;
  box-shadow: 0 0 2px 1px rgba(230, 150, 0, 0.7) !important;
}

.border_red {
  border: 1px solid rgb(230, 0, 0) !important;
  border-radius: 2px;
  box-shadow: 0 0 2px 1px rgba(230, 0, 0, 0.7) !important;
}

.df_col_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.row_2x_950 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-right: -10px;
  margin-left: -10px;

  & > * {
    width: 50%;
    padding: 0 10px;
  }

  @media screen and (max-width: 950px) {
    flex-direction: column;

    & > * {
      width: 100%;
    }
  }
}

.color_lines {
  width: 100%;

  & > * {
    margin-bottom: 10px;
  }

  & > *:nth-of-type(odd) {
    background-color: $grey;
  }
}

.flex_sb {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.red_text {
  color: $red;
}
