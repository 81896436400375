$header-font-size: 13px;

.menu {
  display: flex;
}

.endbuttons {
  align-self: center;
  margin-left: auto;
  padding-right: 5px;
}

.headerBarContainer {
  padding: 0 15px;
  font-size: $header-font-size;
}

.width-100 {
  max-width: 100%;
}
