@import 'scss/variables.scss';

.wrapper {
  display: flex;
  align-items: center;
  padding: 5px;
  gap: 8px;
  border-width: thin;
  border-top-style: solid;
}

.caption {
  padding: 0 10px;
  font-size: $font-size-normal;
}
