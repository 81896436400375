.wrapper {
    display: flex;
    align-items: center;
}

.wrapperIcon {
    display: flex;
    // align-items: center;
}

.png {
    position:absolute;
    margin-left: -5px;
}

.wrapper:hover .png {
    transform: scale(1.4);
    transition: 0.5s;
}

