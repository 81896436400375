@import 'themes/generated/variables.base.scss';

.container {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: $base-bg;
  border-top: 1px solid lightgray;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
