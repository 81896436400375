$white: #fff;

.wrapPopup {
  & > div > div {
    padding: 0;
  }
}

.wrapBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; 
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: $white;
  border-radius: 50%;

  &::after {
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-top: 3px solid black;
    border-right-width: 3px;
    border-left-width: 3px;
    content: '';
  }

  transform-origin: center;
  transition: 0.3s;

  &.rotate {
    transform: rotate(180deg);
  }
}

.df_grow {
  flex-grow: 1;
  text-align: right;

  @media screen and (max-width: 950px) {
    flex-grow: unset;
  }
}

.m_0 {
  margin: 0;
}
