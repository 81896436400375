@use "sass:color";

@import "../themes/generated/variables.base.scss";

$color-white: #fff;
$color-black: #000;
$color-transparent: rgba(0, 0, 0, 0);
$color-hilite: #dcff8a;
$color-primary: $base-accent;

$color-status-success: #9cec83;
$color-status-error: #ff6d4d;
$color-status-pending: #f7e168;
$color-status-warning: rgb(255, 150, 52);
$color-status-warning-bg: rgb(250, 216, 185);
$color-status-default: rgb(236, 236, 236);
$color-status-processing: rgb(127, 180, 250);
$color-status-issucontract: rgb(138, 219, 122);

$color-bg-popover: #fff2c7;
$color-bg-popover-shadow: rgba(209, 178, 91, 0.85);

$color-bg-grey: rgba(191, 191, 191, 0.3);
$color-bg-base: $base-bg;
$color-border-base: $base-border-color;
$color-border-dark: rgba($color-black, 0.5);
$color-font-white: $color-white;
$color-font-base: $base-text-color;
$color-font-grey: #858585;
$color-selected-row: color.scale($color-primary, $lightness: 85%);

$font-size-compact: 12px;
$font-size-normal: 14px;
$font-size-large: 18px;
$font-weight-toast: 500;

$status-colors: (
  'success': $color-status-success,
  'error': $color-status-error,
  'pending': $color-status-pending,
  'warning': $color-status-warning,
  'default': $color-status-default,
  'processing': $color-status-processing,
);

$status-colors-bg: (
  'success': $color-status-success,
  'error': $color-status-error,
  'pending': $color-status-pending,
  'warning': $color-status-warning-bg,
  'default': $color-status-default,
  'processing': $color-status-processing,
);

$light-color-statuses: 'processing', 'warning', 'error';

$form-max-width: 1000px;
$from-large-max-width: 1300px;
$content-default-width: 1000px;
$content-large-width: 1300px;
