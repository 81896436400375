@import "../../themes/generated/variables.base.scss";
@import "../../dx-styles.scss";

$color-phone: #d00000;
$font-size-phone: 15px;
$font-size-phone-icon: 20px;

.header-component {
  z-index: 1;
  flex: 0 0 auto;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  padding: 0;
  text-align: center;
}

.header-title .dx-item-content {
  margin: 0;
  padding: 0;
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button > .dx-button-content {
    padding: 3px;
  }
}

.header-phone {
  display: flex;
  padding-right: 8px;
  color: $color-phone;
}

.header-phone-icon {
  width: 20px;
  height: 20px;
  margin-right: 3px;
  font-size: $font-size-phone-icon;
}

.header-phone-number {
  margin-top: auto;
  font-size: $font-size-phone;
}

.menu-button-badge {
  top: 9px;
  left: -7px;
}

.menu-button-container {
  margin: auto;
}
