@import 'scss/variables.scss';

.wrapper {
    color:black;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    :hover {
        background-color: rgb(240, 239, 239);
    }
    // font-size: $font-size-normal;
}

a.wrapper  {
    color: #000000;
}

.element {
    
    padding:3px 6px;
}
.label {
    padding-right: 8px;
}

.sum {
    color:green;
    font-size: $font-size-normal;
    font-weight: bold;
    padding-right: 5px;
}

.redsum {
    color:rgb(231, 2, 2);
    font-size: $font-size-normal;
    font-weight: bold;
}


