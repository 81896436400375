.dataGridItem {
  width: 100%;
  //max-width: min-content;
}

.partnerColumn {
  max-width: 300px;
}

.dataGrid {
  height: calc(80vh - 100px);
}
