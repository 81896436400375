.side-nav-outer-toolbar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.layout-header {
  z-index: 1501;
}

.layout-body {
  flex: 1;
  min-height: 0;
}

.content {
  flex-grow: 1;
}

.page-content {
  max-height: 0;
}

.toastContainer {
  margin-top: 40px;
}
