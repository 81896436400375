@import '../../scss/variables.scss';

.container {
  display: flex;
}

.delimiter {
  align-self: center;
  padding: 0 6px;
  color: $color-font-grey;
  font-size: $font-size-normal;
  text-align: center;
}
