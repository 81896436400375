.container {
  position: relative;
  display: flex;
  align-items: center;
}

.uploaderLink {
  display: block;
  line-height: 1;
}

.uploaderLinkText {
  display: block;
  width: fit-content;
  max-width: 340px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nofileText {
  position: absolute;
  top: 5px;
  left: 55px;
  width: calc(100% - 55px);
  min-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.folderButton {
  $bg-color-upload-btn: rgb(235, 235, 235);
  $bg-color-upload-btn-hover: rgb(177, 177, 177);
  $icon-size: 19px;

  width: 28px;
  min-width: 28px;
  height: 28px;
  min-height: 28px;
  margin-right: 10px;
  margin-left: 7px;
  background-color: $bg-color-upload-btn;

  &:hover {
    background-color: $bg-color-upload-btn-hover;
  }

  .dx-icon {
    font-size: $icon-size;
  }

  .dx-button-content {
    padding: 0;
  }
}

.removeButton {
  $icon-size: 18px;
  $color-close-btn: transparent;
  $color-close-btn-hover: rgba(253, 0, 0, 0.705);

  width: 18px;
  height: 18px;
  margin-right: 10px;
  font-size: $icon-size;
  background-color: $color-close-btn;

  &:hover {
    color: $color-close-btn-hover;
    cursor: pointer;
  }

  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }
}

.imagePreview {
  max-width: 200px;
  max-height: 200px;
  object-fit: contain;
}
