@import 'scss/variables.scss';

.otk-popover .dx-overlay-content {
  text-align: center;
  background-color: $color-bg-popover;
  border-radius: 8px;
  box-shadow: 2px 8px 9px 0 $color-bg-popover-shadow;

  .dx-popup-content {
    padding: 10px;
  }
}

.otk-popover .dx-popover-arrow::after {
  top: 4px;
  background: #fff;
  border: 1px solid #c2c2c2;
}
