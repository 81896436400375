$grey: rgba(0, 0, 0, 0.5);
$size: 16px;

.fix_icons {
  & i {
    display: inline-block !important;
    margin-right: 10px !important;
    margin-bottom: 4px !important;
  }
}

.wrap {
  margin: 10px;
  padding: 10px;
}

.field {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  & > * {
    flex-shrink: 0;
    margin-right: 10px;
  }

  & > span {
    flex-grow: 1;
    min-width: 290px;
    max-width: 600px;
    font-size: $size;
  }

  p {
    padding: 13px 8px 12px;
    color: $grey;
    border-radius: 2px;
    box-shadow: inset 0 0 0 1px $grey;
  }

  @media screen and (max-width: 950px) {
    & > span {
      max-width: 290px;
    }
  }
}

.image_field {
  margin-bottom: 15px;

  & > div {
    display: flex;
    align-items: center;

    & > * {
      margin-right: 10px;
    }
  }

  span {
    font-size: $size;
  }

  img {
    max-width: 200px;
    max-height: 200px;
    object-fit: contain;
    border: 1px solid $grey;
  }
}
