@import 'scss/variables.scss';

.wrapper {
  display: flex;
  align-items: center;
  padding: 5px;
  border-width: thin;
  border-top-style: solid;
  font-size: $font-size-compact;
}

.caption {
   padding: 0 10px;
  // font-size: $font-size-normal;
}

.filtfields {
  display: flex;
  padding-left: 10px;
  gap: 10px;
}
