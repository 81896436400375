@import "../themes/generated/variables.base.scss";
@import './variables.scss';

$button-font-weight: 400;
$button-font-size: 17px;
$accordion-font-weight: 300;
$accordion-font-size: 18px;

:root {
  --toastify-color-info: #{$color-status-processing};
  --toastify-color-error: #{$color-status-error};
  --toastify-color-success: #{$color-status-success};
  --toastify-color-warning: #{$color-status-warning};
}

body {
  overflow: hidden;
}

input.rightaligntext {
  text-align: right;
}

.dx-toolbar .dx-toolbar-items-container {
  height: 36px;
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused),
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr > td:not(.dx-focused) {
  color: $base-text-color;
  background-color: $color-selected-row;
}

/* stylelint-disable-next-line no-descending-specificity */
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td {
  color: $base-text-color;
  background-color: $color-selected-row;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td.dx-datagrid-group-space,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td.dx-datagrid-group-space,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td.dx-datagrid-group-space,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td.dx-datagrid-group-space {
  border-right-color: $color-selected-row;
}
.dx-datagrid-rowsview .dx-master-detail-row > .dx-master-detail-cell {
  padding: 0px;
}

.otk-normal-button {
  height: 30px;

  .dx-button-text {
    font-weight: $button-font-weight;
    text-transform: none;
  }

  .dx-icon {
    margin-right: 12px !important;
    font-size: $button-font-size !important;
  }

  .dx-button-content {
    display: flex;
    align-items: center;
    padding: 5px 10px;
  }
}

.dx-toast-message {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: $font-weight-toast;
  font-size: $font-size-normal;
  white-space: pre;
}

.dx-toast-warning {
  background-color: $color-status-warning;
}

.dx-field-item-required-mark {
  color: $color-status-error !important;
}

.dx-accordion-item {
  border-top: 1px solid $color-border-base;
  border-bottom: none;
  box-shadow: none;

  &.dx-item-selected {
    border-color: $color-border-base;
  }

  .dx-accordion-item-title {
    padding: 5px 11px;
  }

  &-opened {
    margin: 0;
    border-color: $color-border-base;
    border-bottom: 1px solid $color-border-base;

    &:last-child {
      border-bottom: none;
    }

    .dx-accordion-item-title {
      border-color: $color-primary;
      border-bottom: 2px solid;
    }
  }

  &-body {
    padding: 0;
  }
}

.dx-item.dx-list-item {
  border-top: 1px solid $color-border-base;

  &:first-child {
    border-top: none;
  }
}

.otk-accordion-title {
  margin: 0;
  font-weight: $accordion-font-weight;
  font-size: $accordion-font-size;
}

.otk-cell-no-padding {
  height: 0 !important;
  padding: 0 !important;
}

.otk-datagrid-fullpage {
  height: calc(100vh - 103px);
}

.otk-tag {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 21px;
  margin: 0 3px;
  padding-right: 4px;
  padding-left: 4px;
  color: $color-font-white;
  white-space: nowrap;
  border-radius: 4px;

  &:empty {
    display: none;
  }
}

@each $status-name, $status-color in $status-colors {
  .otk-#{$status-name} {
    color: $status-color;
  }
}

@each $status-name, $status-color in $status-colors {
  .otk-row-status-#{$status-name} {
    box-shadow: inset 8px 0 0 0 $status-color;
  }
}

@each $status-name, $status-color in $status-colors-bg {
  .otk-row-status-#{$status-name}-bg {
    background: $status-color;
  }
}

.dx-popup-content>.otk-row-status-warning{
  padding: 0px;
}

.otk-status {
  color: $base-text-color;
  background: transparent;
}

.otk-row-cell-issubcontract {
  box-shadow: inset 8px 0 0 0 $color-status-issucontract;
}

.issubcontract-tooltip {
  // display:block;
  position: absolute;
  z-index: 1;
  background: yellow;
  // visibility: hidden;
  top: 200px;
  left: calc(var(--x-overflow) / -2);
}

 .otk-row-cell-issubcontract:hover + .issubcontract-tooltip {
  visibility:visible;
  color:red
}


@each $status-name, $status-color in $status-colors {
  .otk-status-#{$status-name} {
    @if index($light-color-statuses, $status-name) {
      color: $color-font-white !important;
    }

    @else {
      color: $base-text-color !important;
    }

    background-color: $status-color;
  }
}

@for $size from 8 through 30 {
  .otk-font-#{$size} {
    font-size: $size + px;
  }
}

.otk-info-bar {
  display: flex;
  align-items: center;
  height: 35px;
  padding-right: 15px;
  padding-left: 15px;
  font-size: $font-size-normal;
  border-bottom: 1px solid $base-border-color;
}

.otk-doc-container {
  width: 100%;
  padding: 10px 10px;
}

.otk-page-container {
  width: 100%;
  padding: 10px 10px;
}

.otk-doc-form {
  position: relative;
  width: 100%;
  max-width: $form-max-width;

  &-large {
    max-width: $from-large-max-width;
  }
}

.otk-dg-toolbar-border {
  border-top: 1px solid;
  border-right: 1px solid;
  border-left: 1px solid;
}

.otk-toolbar {
  padding-right: 10px;
  padding-left: 10px;
  
}

.table-toolbar {
  height: 28px;
  .dx-toolbar-items-container {
    height: 26px;
  }
  background-color: rgba(0, 0, 0, 0.075);
}

.otk-content-block {
  margin: 5px 5px !important;
  padding-bottom: 5px !important;
}

.otk-notification-main-msg {
  color: $color-font-base;
  font-size: $font-size-normal;
}

.otk-page-header {
  display:flex;
  align-items: center;
  justify-content: space-between;
  
  color: $color-font-grey;
  font-weight: $accordion-font-weight;
  font-size: $font-size-large;
  text-transform: uppercase;
}

.otk-section-header {
  padding: 10px 5px 0 0;
  color: $color-font-base;
  font-size: $font-size-large;
}

.dx-field-item:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs):not(.dx-first-row):not(.dx-label-v-align) {
  padding-top: 5px;
  padding-bottom: 5px;
}

.dx-tooltip-wrapper .dx-overlay-content {
  padding: 10px;
  color: $color-black;
  background-color: $color-status-pending;
}

.dx-layout-manager .dx-tabpanel .dx-multiview-item-content {
  padding: 20px 0px 0px 0px;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  border-bottom: 1px solid #e0e0e0;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 14px;
  font-weight: 500;
  font-size: 12px;
}

.dx-datagrid .dx-row > td {
  padding: 8px 8px;
  font-size: 12px;
  line-height: 12px;
}