$base-green: #32a050;
$base-red: #8c2828;
$base-gray: #949494;
$white: #fff;
$white-50: #ddd;
$bgMain: rgb(148 148 148);
$font-size: 30px;
$font-size-18: 18px;
$weight-bold: 700;

.item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 330px;
  margin-right: 15px;
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid $base-gray;
  border-radius: 10px;
}

.container {
  display: flex;
  flex-wrap: wrap;
}
// ================================
.policy_card_wrap {
  @media screen and (max-width: 767px) {
    padding: 25px;

    & > * {
      width: 100%;
    }
  }
}

.sum {
  font-weight: $weight-bold;
  font-size: $font-size-18;

  span {
    color: $base-green;
  }
}
